<template>
  <div class="register-select app_content">
  </div>
</template>

<script>

import {onBeforeMount, reactive, toRefs} from 'vue'
import API from "./api/User";
import req from "./request/request";
import router from "./router";
import {useRoute} from "vue-router";


export default {
  name: 'GetUserInfo',

  setup() {
    onBeforeMount(() => {
      methodsMap.getUserInfo()
    });
    const data = reactive({},
    )

    const methodsMap = {
      getUserInfo: () => {
        const route = useRoute()
        let code = route.query.code
        console.log(code)
        let jumpUrl = route.query.jumpUrl
        let openid = localStorage.getItem("openid")
        if (!code && !openid) {
          let url = "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx448c9fd18b151f92&redirect_uri=https://www.bxthr.com" + route.path
              + (jumpUrl ? "?jumpUrl=" + jumpUrl : "") + "&response_type=code&scope=snsapi_base&state=STATE#wechat_redirect"
          console.log("url:", url)
          window.location.href = url
        } else {
          req.request(API.login, {code: code, openid: openid}, {method: 'POST'}).then((res) => {
            console.log(res)
            let rspJson = res.data.data;
            console.log('headers', res.headers)
            var token = res.headers.token;
            console.log("token", token)
            localStorage.setItem("openid", rspJson.openid)
            if (token) {
              token = res.headers.token;
              localStorage.setItem("userinfo", JSON.stringify(rspJson))
              sessionStorage.setItem("token", token)
            }
            // 登录未获取token 则跳转注册页面
            // if (!token) {
            //   router.push("RegisterSelect")
            //   return;
            // }
            console.log('jumpUrl',jumpUrl)
            if (jumpUrl) {
             router.replace(jumpUrl)
            } else {
              router.replace("ServiceCenter")
            }
          })
        }
      },
    }


    return {
      ...toRefs(data),
      ...methodsMap,
    };
  }
}
</script>
<style lang="less" scoped>
.app_content {
  .warp_body {

    .registerSelect {
      width: 90%;
      margin: 40px auto;
    }

    .protocol {
      text-align: center;
      font-size: 10px;
      color: blue;
    }
  }
}
</style>
